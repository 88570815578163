import React, { useEffect, useRef } from "react";
import { Link } from "gatsby";
import { colours } from "../../vars/colours";
import styled from "styled-components";
import { fonts } from "../../vars/fonts";
import Border from "../../images/border.svg";
import { device } from "../../vars/media";
// import gsap from "gsap";
// import { circ, cubic, quint } from "../../vars/ease";

const Button = ({ children, link, external, className }) => {
  const ref = useRef(null);
  const tl = useRef();
  const gridLink =
    typeof link === "object" && link !== null && link.navigationItemUrl;
  const gridExternal = gridLink.externalUrl !== null;
  // useEffect(() => {
  //   const icon = ref.current.querySelector(".icon");
  //   const svg = icon.querySelector("svg");
  //   const text = ref.current.querySelector("a span");
  //   tl.current = gsap.timeline({ paused: true });

  //   tl.current
  //     .to(
  //       icon,
  //       {
  //         background: colours.accent,
  //         borderColor: colours.accent,
  //         duration: 0.646,
  //         ease: quint,
  //       },
  //       0
  //     )

  //     .to(
  //       icon,
  //       {
  //         width: "calc(100% + 8px)",
  //         paddingRight: "7px",
  //         ease: quint,
  //         duration: 1.1,
  //         willChange: "transform",
  //       },
  //       0
  //     )

  //     .to(
  //       svg,
  //       {
  //         fill: colours.white,
  //         ease: quint,
  //         duration: 1.1,
  //       },
  //       0
  //     )

  //     .to(
  //       text,
  //       {
  //         x: "-2.2rem",
  //         ease: circ,
  //         duration: 1.1,
  //         color: colours.white,
  //       },
  //       0
  //     );

  //   ref.current.addEventListener("mouseenter", () => {
  //     tl.current.timeScale(2.2).play();
  //   });

  //   ref.current.addEventListener("mouseleave", () => {
  //     tl.current.timeScale(3).reverse();
  //   });
  // }, []);

  return gridLink ? (
    <StyledButton
      className={className ? `${className} button` : "button"}
      ref={ref}
    >
      <Border />
      {!gridExternal && (
        <Link to={gridLink.internalLink.slug.current}>{children}</Link>
      )}
      {gridExternal && (
        <a href={gridLink.externalUrl} target="_blank">
          {children}
        </a>
      )}
    </StyledButton>
  ) : (
    <StyledButton
      className={className ? `${className} button` : "button"}
      ref={ref}
    >
      <Border />
      {!external && (
        <Link to={`/${link}`} partiallyActive activeClassName="active">
          {children}
        </Link>
      )}
      {external && (
        <a href={link} target="_blank">
          {children}
        </a>
      )}
    </StyledButton>
  );
};

const StyledButton = styled.div`
  margin: 1.56rem 0;
  display: inline-block;
  position: relative;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  &:hover {
    svg {
      fill: ${colours.accent};

      path {
        stroke: #af471c;
      }
    }

    a {
      color: ${colours.primary} !important;
    }
  }

  svg {
    fill: ${colours.primary};
    /* position: absolute; */
    top: 0;
    left: 0;
    width: 154px !important;
    height: 52px;
    pointer-events: none;
    z-index: 0;
    transition: fill 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);

    path {
      transition: stroke 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
    }
  }

  a {
    transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
    position: absolute;
    cursor: pointer;
    text-decoration: none;
    color: ${colours.body};
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: 1.1px;
    font-family: ${fonts.accent};
    display: inline-flex;
    align-items: center;
    padding: 1.25rem;
    line-height: 0.4;
    white-space: nowrap;
    height: 52px;
    width: 100%;
    text-align: center;
    justify-content: center;
    margin-top: 2px;
  }

  ${device.laptopL} {
    a {
      font-size: 1.0625rem;
      padding: 1.25rem 1.475rem;
      line-height: 1.25;
    }
  }
`;

export default Button;
