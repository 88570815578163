import React, { useEffect, useRef, useState, useContext } from "react";
import styled from "styled-components";
import gsap from "gsap";
import { GlobalStateContext } from "../../context/Global";
import ScrollTrigger from "gsap/ScrollTrigger";
import { colours } from "../../vars/colours";
import { StaticImage } from "gatsby-plugin-image";
import { cubic, quint, quintOut } from "../../vars/ease";
import Logo from "../../images/logo.svg";
import Mask from "../../images/borderMask.svg";

const Preloader = (location) => {
  gsap.registerPlugin(ScrollTrigger);
  const { preloaded, setPreloaded, setTransitioned } =
    useContext(GlobalStateContext);
  const [loaded, setLoaded] = useState(false);
  const tl = useRef(null);
  const imgTl = useRef(null);
  const pre = useRef(null);
  const path = location.location.pathname;

  useEffect(() => {
    const img = pre.current.querySelectorAll(".gatsby-image-wrapper");
    const mask = pre.current.querySelectorAll(".mask-outer");
    const svg = pre.current.querySelectorAll("svg path");

    setTransitioned(true);

    if (path !== "/") {
      setPreloaded(true);
      gsap.to("body", {
        delay: 1,
        opacity: 1,
        duration: 0.5,
        ease: quint,
      });
    }

    if (path == "/" && !preloaded && loaded) {
      gsap.set("body", { opacity: 1 });

      imgTl.current = gsap.timeline({ paused: true });

      imgTl.current.to(mask, {
        delay: 0.24,
        autoAlpha: 1,
        duration: 0,
        ease: "none",
        stagger: {
          amount: 0.8,
          ease: "power1.in",
        },
      });

      tl.current = gsap.timeline({
        paused: true,
        onComplete: () => {
          setPreloaded(true);
        },

        // onStart: () => {
        //   setTransitioned(true);
        // },
      });

      tl.current
        .from(
          img,
          {
            scale: 2.2,
            duration: 3.2,
            ease: quintOut,
          },
          0.24
        )
        .from(
          mask,
          {
            scale: 0,
            duration: 3.5,
            ease: quintOut,
          },
          0.24
        )
        .to(
          svg,
          {
            y: 0,
            stagger: -0.04,
            duration: 1.8,
            ease: quintOut,
          },
          0.87
        )
        .to(
          pre.current.querySelector(".inner-wrap"),
          {
            autoAlpha: 0,
            duration: 0.8,
          },
          3
        )

        .to(
          pre.current,
          {
            autoAlpha: 0,
            duration: 0.6,
          },
          3.4
        );

      imgTl.current.play(0);
      tl.current.play(0);
    }
  }, [loaded]);

  return (
    !preloaded && (
      <Preload ref={pre}>
        <div className="inner-wrap">
          <div className="img-wrap">
            <div className="mask-outer">
              <Mask className="mask-border" />
              <div className="img-mask">
                <StaticImage
                  loading="eager"
                  src="../../images/preload-one.jpg"
                  alt=""
                  onStartLoad={() => setLoaded(true)}
                  r
                />
              </div>
            </div>
            <div className="mask-outer">
              <Mask className="mask-border" />
              <div className="img-mask">
                <StaticImage
                  loading="eager"
                  src="../../images/preload-two.jpg"
                  alt=""
                />
              </div>
            </div>
            <div className="mask-outer">
              <Mask className="mask-border" />
              <div className="img-mask">
                <StaticImage
                  loading="eager"
                  src="../../images/preload-three.jpg"
                  alt=""
                />
              </div>
            </div>
            <div className="mask-outer">
              <Mask className="mask-border" />
              <div className="img-mask">
                <StaticImage
                  loading="eager"
                  src="../../images/preload-four.jpg"
                  alt=""
                />
              </div>
            </div>
            <div className="mask-outer">
              <Mask className="mask-border" />
              <div className="img-mask">
                <StaticImage
                  loading="eager"
                  src="../../images/preload-five.jpg"
                  alt=""
                />
              </div>
            </div>
            <div className="mask-outer">
              <Mask className="mask-border" />
              <div className="img-mask">
                <StaticImage
                  loading="eager"
                  src="../../images/preload-one.jpg"
                  alt=""
                />
              </div>
            </div>
            <div className="mask-outer">
              <Mask className="mask-border" />
              <div className="img-mask">
                <StaticImage
                  loading="eager"
                  src="../../images/preload-two.jpg"
                  alt=""
                />
              </div>
            </div>
            <div className="mask-outer">
              <Mask className="mask-border" />
              <div className="img-mask">
                <StaticImage
                  loading="eager"
                  src="../../images/preload-three.jpg"
                  alt=""
                />
              </div>
            </div>
            <div className="mask-outer">
              <Mask className="mask-border" />
              <div className="img-mask">
                <StaticImage
                  loading="eager"
                  src="../../images/preload-four.jpg"
                  alt=""
                />
              </div>
            </div>
            <div className="mask-outer">
              <Mask className="mask-border" />
              <div className="img-mask">
                <StaticImage
                  loading="eager"
                  src="../../images/preload-five.jpg"
                  alt=""
                />
              </div>
            </div>
          </div>

          <div className="logo-wrap">
            <Logo />
          </div>
        </div>
      </Preload>
    )
  );
};

export default Preloader;

const Preload = styled.div`
  pointer-events: none;
  background-color: ${colours.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: visible;
  pointer-events: none;
  z-index: 11;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;

  .logo-wrap {
    overflow: hidden;
    svg {
      path {
        transform: translateY(150%);
      }
    }
  }

  .inner-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* transform: translateY(33px); */
  }

  .img-wrap {
    width: 5rem;
    height: 5rem;
  }

  .mask-border {
    z-index: 2;
    /* display: none; */
    /* width: 25px; */
    width: 76px;
    height: 69px;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0 auto;
  }

  .mask-outer {
    opacity: 0;
    display: inline-flex;
    position: absolute;
  }

  .img-mask {
    will-change: transform;
    overflow: hidden;
    mask-image: url("data:image/svg+xml,%3Csvg width='76' height='69' viewBox='0 0 76 69' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M76 13.2481V69H0.18192L2.27656e-05 13.4743C-0.00298374 12.7636 0.31721 12.1021 0.846355 11.7179C6.31218 7.76646 37.4581 -12.38 75.056 11.4339C75.6377 11.8012 76 12.4949 76 13.2481Z' fill='%23D9D9D9'/%3E%3C/svg%3E%0A");
    transform: translateZ(0);
    width: 76px;
    height: 69px;
    mask-size: 100%;
    mask-repeat: no-repeat;
  }

  .gatsby-image-wrapper {
    will-change: transform;
    height: 100%;
    width: 100%;
    position: absolute;
    object-fit: cover;
  }

  .img-mask:nth-of-type(1) {
    opacity: 1;
  }
`;
