import React from "react";

import Page from "./src/components/Page";
import Preloader from "./src/components/global/Preloader";
import PageTransition from "./src/components/global/PageTransition";

import "./src/fonts/EngraversGothicW01-Bold.woff";
import "./src/fonts/EngraversGothicW01-Bold.woff2";
import "./src/fonts/GothamMedium.woff";
import "./src/fonts/GothamMedium.woff2";
import "./src/fonts/OfficeTimesRound-Regular.woff";
import "./src/fonts/OfficeTimesRound-Regular.woff2";
import "./src/fonts/OfficeTimesRound-RoundMono.woff";
import "./src/fonts/OfficeTimesRound-RoundMono.woff2";

import { GlobalStateProvider } from "./src/context/Global";

export const shouldUpdateScroll = () => false;

export const wrapPageElement = ({ element, props }) => {
  const path = props.location.pathname.path;
  return (
    <GlobalStateProvider>
      <Preloader location={props.location} />
      <PageTransition location={props.location}>
        <Page {...props}>{element}</Page>
      </PageTransition>
    </GlobalStateProvider>
  );
};
