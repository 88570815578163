import React, { Children } from "react";
import styled from "styled-components";
import { useEffect } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useRef } from "react";
import { cubic, quint, quintOut } from "../../vars/ease";

const FadeUp = ({ children, delay }) => {
  const ref = useRef(null);

  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    setTimeout(() => {
      const elem = ref.current;

      gsap.set(elem, {
        autoAlpha: 0,
        y: 20,
      });

      ScrollTrigger.create({
        trigger: elem,
        start: "top 90%",
        onEnter: () => {
          gsap.to(elem, {
            delay: delay,
            y: 0,
            autoAlpha: 1,
            duration: 1.6,
            ease: quintOut,
          });
        },
      });
    }, 100);
  }, []);

  return <StyledFadeUp ref={ref}>{children}</StyledFadeUp>;
};

export default FadeUp;

const StyledFadeUp = styled.div`
  will-change: transform;
`;
