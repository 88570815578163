import gsap from "gsap";
import { CustomEase } from "gsap/CustomEase";

gsap.registerPlugin(CustomEase);
export const circ = CustomEase.create("circ", "0.96, 0, 0.01, 0.99");
export const cubic = CustomEase.create("cubic", "0.65, 0, 0.35, 1");
export const quint = CustomEase.create("quint", "0.83, 0, 0.17, 1");
export const quintIn = CustomEase.create("quintIn", "1, 0, 0.64, 1.01");
// export const quintOut = CustomEase.create("quintOut", ".42,.53,.43,.99");
export const quintOut = CustomEase.create("quintOut", ".1,.91,.23,1");
