import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import styled from "styled-components";
import Wrapper from "../global/Wrapper";
import { Link, graphql, useStaticQuery } from "gatsby";
import Logo from "../../images/logo.svg";
import { colours } from "../../vars/colours";
import { device } from "../../vars/media";
import { gsap } from "gsap";
import { cubic, quint } from "../../vars/ease";
import { StaticImage } from "gatsby-plugin-image";
import { quintOut } from "../../vars/ease";
import { fonts } from "../../vars/fonts";
import Sun from "./Sun";
import Button from "./Button";
import Caption from "./Caption";

const Header = () => {
  const data = useStaticQuery(query);
  const ref = useRef(null);
  const navRef = useRef(null);
  const tl = useRef(null);
  const headerTl = useRef(null);
  const [toggleNav, setToggleNav] = useState(false);
  const menus = data.allSanityNavigation.edges;
  const menuLeft = menus.filter((m) => m.node.navId.current === "1");
  const menuRight = menus.filter((m) => m.node.navId.current === "2");
  const mainMenu = menus.filter((m) => m.node.navId.current === "3");
  const bottomMenu = menus.filter((m) => m.node.navId.current === "4");

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      const topItems = navRef.current.querySelectorAll(
        ".menu-top a:not(.menu-top .button a), .menu-top .button"
      );
      const linesNav = navRef.current.querySelectorAll(".line");
      const linesHead = ref.current.querySelectorAll(".line");
      gsap.set(topItems, {
        autoAlpha: 0,
      });

      gsap.fromTo(
        ref.current,
        {
          autoAlpha: 0,
        },
        {
          autoAlpha: 1,
          duration: 1.3,
        }
      );

      tl.current = gsap.timeline({
        paused: true,
        onStart: () =>
          gsap.set(navRef.current, {
            pointerEvents: "all",
          }),
        onReverseComplete: () => {
          gsap.set(navRef.current, {
            clearProps: "all",
          });

          gsap.set(document.querySelector(".ol"), {
            clearProps: "all",
          });
        },
      });

      tl.current
        .to(
          document.querySelector(".ol"),
          {
            autoAlpha: 1,
            duration: 1.7,
            ease: quint,
          },
          0
        )
        .to(
          linesHead[0],
          {
            rotate: 45,
            y: 0,
            duration: 1.7,
            ease: quint,
          },
          0
        )
        .to(
          linesHead[1],
          {
            rotate: -45,
            y: 0,

            duration: 1.7,
            ease: quint,
          },
          0
        )
        .to(
          linesNav[0],
          {
            opacity: 1,
            rotate: 45,
            y: 0,
            duration: 1.7,
            ease: quint,
          },
          0
        )
        .to(
          linesNav[1],
          {
            opacity: 1,
            rotate: -45,
            y: 0,
            duration: 1.7,
            ease: quint,
          },
          0
        )
        .to(
          topItems,
          {
            autoAlpha: 1,
            stagger: 0.08,
            duration: 0.8,
            ease: cubic,
          },
          0.5
        )
        .to(
          navRef.current.querySelector(".nav__wrap"),
          {
            x: 0,
            duration: 1.4,
            ease: quint,
          },
          0
        )
        .to(
          navRef.current.querySelector(".nav__inner"),
          {
            x: 0,
            duration: 1.4,
            ease: quint,
          },
          0
        )
        .to(
          navRef.current.querySelector(".gatsby-image-wrapper"),
          {
            scale: 1,
            duration: 1.4,
            ease: quintOut,
          },
          0.2
        )
        .from(
          navRef.current.querySelector(".caption"),
          {
            autoAlpha: 0,
            y: 5,
            duration: 1,
            ease: quintOut,
          },
          1.2
        );
    }, ref);

    return () => ctx.revert();
  }, []);

  useEffect(() => {
    const logo = ref.current.querySelector(".logo svg");
    const logoPath = ref.current.querySelectorAll(
      ".logo svg path:not(.logo svg path:last-of-type)"
    );
    const p = ref.current.querySelector(".logo svg path:last-of-type");
    const a = ref.current.querySelectorAll("a");
    const bg = ref.current.querySelector(".header__bg");
    const linesNav = navRef.current.querySelectorAll(".hamburger");
    const hmb = ref.current.querySelectorAll(".hamburger .line");
    let mm = gsap.matchMedia();

    mm.add("(min-width: 1200px)", () => {
      const headerTl = gsap.timeline({
        scrollTrigger: {
          start: "+=30px",
          toggleActions: "play none none reverse",
        },
      });

      headerTl
        .to(
          ref.current,
          {
            paddingTop: "0.6rem",
            paddingBottom: "0.6rem",
            duration: 0.6,
            ease: cubic,
          },
          0.2
        )
        .to(
          linesNav,
          {
            top: "30px",
            duration: 0.6,
            ease: cubic,
          },
          0.2
        )
        .to(
          logoPath,
          {
            // width: "37px",
            autoAlpha: 0,
            // yPercent: 130,
            duration: 0.5,
            ease: cubic,
            // stagger: -0.04,
          },
          0
        )
        .to(
          p,
          {
            fill: colours.body,

            // width: "37px",
            x: 42,
            duration: 0.6,
            ease: cubic,
          },
          0.2
        )
        .to(
          bg,
          {
            scaleY: 1,
            duration: 0.6,
            ease: cubic,
          },
          0.2
        )
        .to(
          hmb,
          {
            backgroundColor: colours.body,
            duration: 0.5,
            ease: cubic,
          },
          0.2
        )
        .to(
          a,
          {
            color: colours.body,
            duration: 0.5,
            ease: cubic,
          },
          0.2
        );
    });

    const bookNow = document.querySelector(".active.Book");
    const scroll = document.querySelectorAll(".grid");

    bookNow &&
      bookNow.addEventListener("click", () => {
        window.scrollTo(0, scroll[0].offsetTop - 100);
      });

    return () => {
      mm.revert();
    };
  }, []);

  useEffect(() => {
    toggleNav
      ? tl.current.timeScale(1).play()
      : tl.current.timeScale(1.7).reverse();
  }, [toggleNav]);

  return (
    <>
      <StyledHeader ref={ref}>
        <div className="header__bg"></div>
        <Wrapper outer>
          <div className="header-wrap">
            <div className="header__nav header__nav-left">
              {menuLeft[0].node.items.map((item) => {
                return item.navigationItemUrl.externalUrl ? (
                  <a href={item.navigationItemUrl.externalUrl} target="_blank">
                    {item.text}
                  </a>
                ) : (
                  <Link
                    activeClassName="active"
                    partiallyActive="true"
                    to={`/${item.navigationItemUrl.internalLink.slug.current}`}
                  >
                    {item.text}
                  </Link>
                );
              })}
            </div>
            <Link to="/" className="logo">
              <Logo />
            </Link>

            <div className="header__nav header__nav-right">
              {menuRight[0].node.items.map((item) => {
                return item.navigationItemUrl.externalUrl ? (
                  <a href={item.navigationItemUrl.externalUrl} target="_blank">
                    {item.text}
                  </a>
                ) : (
                  <Link
                    activeClassName={`active ${item.text}`}
                    to={`/${item.navigationItemUrl.internalLink.slug.current}`}
                  >
                    {item.text}
                  </Link>
                );
              })}

              <Hamburger
                className="hamburger"
                onClick={() => setToggleNav(!toggleNav)}
              >
                <div className="line"></div>
                <div className="line"></div>
              </Hamburger>
            </div>
          </div>
        </Wrapper>
      </StyledHeader>

      <Nav ref={navRef} className="nav">
        <Hamburger
          className="hamburger"
          onClick={() => setToggleNav(!toggleNav)}
        >
          <div className="line"></div>
          <div className="line"></div>
        </Hamburger>
        <div className="nav__wrap">
          <div className="nav__inner">
            <div className="nav__inner-left">
              <StaticImage
                src="../../images/nav-main.jpg"
                alt="Parallel Cardiff image"
              ></StaticImage>

              <Caption>Parallel, Cardiff</Caption>
            </div>
            <div className="nav__inner-right">
              <div className="menu-top">
                <Sun />
                {mainMenu[0].node.items.map((item) => {
                  return item.navigationItemUrl.externalUrl ? (
                    <Button link={item.navigationItemUrl.externalUrl} external>
                      {item.text}
                    </Button>
                  ) : (
                    <Link
                      to={`/${item.navigationItemUrl.internalLink.slug.current}`}
                      activeStyle={{ color: "#1332386b" }}
                    >
                      {item.text}
                    </Link>
                  );
                })}
              </div>

              <div className="menu-bottom">
                {bottomMenu[0].node.items.map((item) => {
                  return item.navigationItemUrl.externalUrl ? (
                    <a
                      href={item.navigationItemUrl.externalUrl}
                      target="_blank"
                    >
                      {item.text}
                    </a>
                  ) : (
                    <Link
                      to={`/${item.navigationItemUrl.internalLink.slug.current}`}
                    >
                      {item.text}
                    </Link>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </Nav>
    </>
  );
};

export default Header;

const StyledHeader = styled.header`
  display: flex;
  width: 100%;
  position: absolute;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 4;
  padding: 1.875rem;

  .header__bg {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transform: scaleY(0);
    background-color: ${colours.primary};
    transform-origin: top;
    will-change: transform;
  }

  .header__nav {
    a {
      transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
      &:hover {
        color: ${colours.accent} !important;

        &::after {
          transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
          color: ${colours.accent} !important;
        }
      }

      &.active:not(.Book) {
        position: relative;

        &:after {
          content: "◆";
          position: absolute;
          top: 90%;
          left: 0;
          right: 0;
          margin: 0 auto;
          color: ${colours.body};
          font-size: 0.9rem;
          text-align: center;
        }
      }
    }
  }

  ${device.laptopM} {
    position: fixed;
  }

  .wrapper {
    width: 100%;
  }

  svg {
    width: 5.5rem;
    path {
      fill: ${colours.body};
    }
  }

  .header__nav {
    display: none;
  }

  .header-wrap {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .hamburger {
    width: 22px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 4px;

    .line {
      position: absolute;
      width: 22px;
      height: 2px;
      background-color: ${colours.body};
      transform: translateY(4px);

      &:nth-of-type(1) {
        transform: translateY(-2px);
      }
    }
  }

  ${device.laptopM} {
    .header-wrap {
      justify-content: space-between;
    }

    .header__nav {
      flex: 1;
      display: flex;
      align-content: center;
      margin: 0 -0.9rem;

      a {
        margin: 0 0.9rem;
        color: ${colours.body};
      }

      .hamburger {
        margin-right: 0.9rem;
      }

      &-right {
        justify-content: flex-end;
      }
    }

    svg {
      width: unset;
    }
  }

  ${device.laptopL} {
    padding: 1.875rem 3.75rem;

    .header__nav {
      margin: 0 -1rem;

      a {
        font-size: 1.0625rem;
        margin: 0 1rem;
      }

      .hamburger {
        margin-right: 1rem;
      }
    }
  }
`;

const Hamburger = styled.div`
  width: 22px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4px;

  .line {
    position: absolute;
    width: 22px;
    height: 2px;
    background-color: ${colours.body};
    transform: translateY(4px);

    &:nth-of-type(1) {
      transform: translateY(-2px);
    }
  }
`;

///Nav////

const Nav = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 5;
  pointer-events: none;
  overflow: hidden;

  a {
    transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
    &:hover {
      color: ${colours.accent};
    }
  }

  .hamburger {
    position: fixed;
    right: 70px;
    top: 50px;
    z-index: 6;

    .line {
      opacity: 0;
      /* display: none; */
    }
  }

  .nav__inner,
  .nav__wrap {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .nav__wrap {
    background-color: ${colours.primary};
    transform: translateX(100vw);
    min-height: 720px;
  }

  .nav__inner {
    transform: translateX(-100vw);
    display: flex;

    &-left {
      position: relative;
      overflow: hidden;
      width: 55%;
      background-color: ${colours.body};

      .gatsby-image-wrapper {
        height: 100%;
        transform: scale(1.2);
        transform-origin: center;

        img {
          object-position: 50% 30%;
        }
      }
    }

    &-right {
      position: relative;
      width: 45%;
    }
  }

  .menu-top {
    padding: 3rem;
    padding-top: 1.5rem;
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    .sun {
      display: none;
      margin-bottom: 1rem;
      margin-top: -30px;
    }

    a:not(.button a) {
      font-family: ${fonts.primary};
      text-transform: capitalize;
      letter-spacing: 0;
      font-size: 1.3rem;
      padding: 0.8rem 0;
    }

    .button {
      min-height: 52px;
      margin: 0.4rem 0;

      a {
        font-size: 1rem;
      }
    }
  }

  .menu-bottom {
    border-top: 1px dashed ${colours.body};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem 1.75rem;
    width: 100%;
    overflow: scroll;
    position: absolute;
    bottom: 0;
    left: 0;

    a {
      padding: 0 0.5rem;
    }
  }

  ${device.laptopL} {
    .menu-top {
      a:not(.button a) {
        font-size: 1.3rem;
        padding: 0.64rem 0;
      }

      .button {
        &:nth-of-type(2) {
          margin-top: 1.3rem;
        }

        a {
          font-size: 1rem;
        }
      }
    }

    .menu-bottom {
      padding: 2.5rem;

      a {
        padding: 0 1rem;
      }
    }
  }
`;

export const query = graphql`
  query Nav {
    allSanityNavigation {
      edges {
        node {
          navId {
            current
          }
          title
          items {
            text
            navigationItemUrl {
              externalUrl
              internalLink {
                ... on SanityLocation {
                  slug {
                    current
                  }
                }
                ... on SanityMenu {
                  slug {
                    current
                  }
                }
                ... on SanityPage {
                  slug {
                    current
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
