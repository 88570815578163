import { gsap } from "gsap";
import { DrawSVGPlugin, ScrollTrigger } from "gsap/all";
import React, { useEffect, useLayoutEffect, useRef } from "react";
import styled from "styled-components";
import { cubic, quint, quintIn, quintOut } from "../../vars/ease";

const Sun = () => {
  const ref = useRef(null);
  gsap.registerPlugin(DrawSVGPlugin, ScrollTrigger);

  useEffect(() => {
    const path = ref.current.querySelectorAll("path");

    let ctx = gsap.context(() => {
      gsap.set(path, {
        drawSVG: 0,
      });

      ScrollTrigger.create({
        trigger: ref.current,
        start: "top 90%",
        onEnter: () => {
          gsap.to(path, {
            delay: 0.3,
            duration: 1,
            stagger: 0.1,
            drawSVG: "100%",
          });
        },
      });
    }, ref);

    return () => ctx.revert();
  }, []);

  return (
    <StyledSun className="sun" ref={ref}>
      <svg
        width="31"
        height="19"
        viewBox="0 0 31 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.5 15.3999C15.9 15.3999 16.2 15.6999 16.2 16.0999C16.2 16.4999 15.9 16.7999 15.5 16.7999C15.1 16.7999 14.8 16.4999 14.8 16.0999C14.9 15.6999 15.2 15.3999 15.5 15.3999ZM15.5 13.8999C14.3 13.8999 13.3 14.8999 13.3 16.0999C13.3 17.2999 14.3 18.2999 15.5 18.2999C16.7 18.2999 17.7 17.2999 17.7 16.0999C17.7 14.7999 16.7 13.8999 15.5 13.8999Z"
          fill="#133238"
        />
        <path
          d="M15.4 11.5V1"
          stroke="#133238"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M12.5 12.8L7.90002 8.30005"
          stroke="#133238"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M18.6 12.8L23.1 8.30005"
          stroke="#133238"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M10.9 16H0.799988"
          stroke="#133238"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M20.1 16H30.2"
          stroke="#133238"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
      </svg>
    </StyledSun>
  );
};

export default Sun;

const StyledSun = styled.div``;
