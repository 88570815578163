import React, { useRef, useLayoutEffect, useEffect, useContext } from "react";
import { ScrollSmoother } from "gsap/ScrollSmoother";
import { ScrollTrigger } from "gsap/all";
import Header from "./partials/Header";
import Footer from "./partials/Footer";
import "../style.css";
import gsap from "gsap";
import { GlobalStateContext } from "../context/Global";
import Nav from "./global/Nav";

const Page = ({ children }) => {
  gsap.registerPlugin(ScrollSmoother, ScrollTrigger);
  const { transitioned } = useContext(GlobalStateContext);

  const wrapper = useRef();
  const content = useRef();

  gsap.config({
    nullTargetWarn: false,
    trialWarn: false,
    force3D: true,
  });

  if ("scrollRestoration" in window.history) {
    window.history.scrollRestoration = "manual";
  }

  useLayoutEffect(() => {
    let touch = ScrollTrigger.isTouch;
    let smoother = ScrollSmoother.create({
      effects: touch !== 1 ? true : false,
      wrapper: wrapper.current,
      content: content.current,
      smooth: 1.1,
      smoothTouch: false,
      ignoreMobileResize: true,
      // normalizeScroll: touch == 1 ? true : false,
    });

    return () => {
      smoother && smoother.kill();
    };
  }, [transitioned]);

  return (
    <>
      <main>
        <div className="ol"></div>
        {transitioned && <Header />}
        {transitioned && <Nav></Nav>}
        <div ref={wrapper} className="inset">
          <div ref={content} className="content">
            {transitioned && (
              <>
                {children}
                <Footer />
              </>
            )}
          </div>
        </div>
      </main>
    </>
  );
};

export default Page;
