import React, {
  useEffect,
  useRef,
  useState,
  useLayoutEffect,
  useContext,
} from "react";
import styled from "styled-components";
import { Link, graphql, useStaticQuery } from "gatsby";
import { colours } from "../../vars/colours";
import { fonts } from "../../vars/fonts";
import Border from "../../images/border.svg";
import { device } from "../../vars/media";
import Button from "../partials/Button";
import { gsap } from "gsap";
import { cubic, quint } from "../../vars/ease";
import { GlobalStateContext } from "../../context/Global";

const Nav = () => {
  const data = useStaticQuery(query);
  const tl = useRef(null);
  const ref = useRef(null);
  const navRef = useRef(null);
  const [toggleNav, setToggleNav] = useState(false);
  const menus = data.allSanityNavigation.edges;
  const mainMenu = menus.filter((m) => m.node.navId.current === "3");
  const { preloaded } = useContext(GlobalStateContext);

  useLayoutEffect(() => {
    let mm = gsap.matchMedia();

    tl.current = gsap.timeline({
      paused: true,
      onStart: () =>
        gsap.set(ref.current.querySelector(".nav__bg"), {
          pointerEvents: "all",
        }),
      onReverseComplete: () => {
        gsap.set(ref.current.querySelector(".nav__bg"), {
          clearProps: "all",
        });

        gsap.set(document.querySelector(".ol"), {
          clearProps: "all",
        });
      },
    });

    mm.add(
      "(max-width: 1200px)",
      () => {
        // const items = navRef.current.querySelectorAll("a");
        const lines = ref.current.querySelectorAll(".line");

        tl.current
          .to(
            lines[0],
            {
              rotate: 45,
              y: 1,
              duration: 0.94,
              ease: quint,
            },
            0
          )
          .to(
            lines[1],
            {
              rotate: -45,
              y: 1,
              duration: 0.94,
              ease: quint,
            },
            0
          )

          .to(
            document.querySelector(".ol"),
            {
              autoAlpha: 1,
              duration: 0.94,
              ease: quint,
            },
            0
          )
          .to(
            ".nav__bg-items",
            {
              duration: 0.94,
              ease: quint,
            },
            0
          )
          .to(
            ".nav__bg-items a:not(.nav__bg-items .button a), .nav__bg-items .button",
            {
              autoAlpha: 1,
              stagger: 0.05,
              duration: 0.94,
              ease: cubic,
            },
            0.8
          )
          .to(
            ".nav__bg",
            {
              y: 0,
              duration: 0.94,
              ease: quint,
            },
            0
          );
        // .to(
        //   navRef.current.querySelector(".nav__inner"),
        //   {
        //     x: 0,
        //     duration: 1.7,
        //     ease: quint,
        //   },
        //   0
        // )
        // return () => tl.current.clear();
      },
      ref
    );

    return () => {
      mm.revert();
    };
  }, []);

  useLayoutEffect(() => {
    const btns = ref.current.querySelectorAll(".nav-button");

    preloaded &&
      gsap.to(btns, {
        delay: 1,
        y: 0,
        autoAlpha: 1,
        duration: 0.6,
        ease: cubic,
        stagger: 0.1,
      });
  }, [preloaded]);

  useEffect(() => {
    toggleNav
      ? tl.current.timeScale(1).play()
      : tl.current.timeScale(1.4).reverse();
  }, [toggleNav]);

  return (
    <StyledNav ref={ref}>
      <div className="nav__bg">
        <div className="nav__bg-items">
          {mainMenu[0].node.items.map((item) => {
            return item.navigationItemUrl.externalUrl ? (
              <Button link={item.navigationItemUrl.externalUrl} external>
                {item.text}
              </Button>
            ) : (
              <Link
                to={`/${
                  item.navigationItemUrl.internalLink.slug.current == "home"
                    ? ""
                    : item.navigationItemUrl.internalLink.slug.current
                }`}
                activeStyle={{ color: "#1332386b" }}
              >
                {item.text}
              </Link>
            );
          })}
        </div>
      </div>
      <div
        className="nav-button nav-button--menu"
        onClick={() => setToggleNav(!toggleNav)}
      >
        <Border />
        <span>
          Menu
          <Hamburger className="hamburger">
            <div className="line"></div>
            <div className="line"></div>
          </Hamburger>
        </span>
      </div>

      <Button link="locations" className="nav-button nav-button--book">
        Book now
      </Button>

      <Button
        link="replace"
        external
        className="nav-button nav-button--book replace"
      >
        Book now
      </Button>
    </StyledNav>
  );
};

export default Nav;

const StyledNav = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  overflow: hidden;

  .button {
    margin: 0.4rem 0;

    &:nth-of-type(1) {
      margin-top: 2rem;
    }
  }

  .nav__bg {
    pointer-events: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${colours.primary};
    z-index: 0;
    transform: translateY(100%);
    padding: 3rem;
    display: flex;
    /* align-items: center; */
    /* justify-content: center; */
    overflow: scroll;
    height: 100vh;
    padding-bottom: 5.5rem;

    &-items {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      height: 100%;
      min-height: 400px;

      .button {
        /* margin-top: 0; */
      }

      a:not(.button a),
      .button {
        opacity: 0;
      }

      a:not(.button a) {
        font-family: ${fonts.primary};
        letter-spacing: normal;
        text-transform: none;
        font-size: 1.4rem;
        padding: 0.5rem 0;
      }
    }
  }

  .nav-button {
    will-change: transform;
    margin: 1.25rem 0.25rem;
    display: inline-block;
    position: relative;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    transform: translateY(5px);

    svg {
      /* position: absolute; */
      top: 0;
      left: 0;
      width: 154px;
      height: 52px;
      pointer-events: none;
      z-index: 0;
    }

    span {
      position: absolute;
      cursor: pointer;
      text-decoration: none;
      color: ${colours.body};
      font-size: 1rem;
      text-transform: uppercase;
      letter-spacing: 1.1px;
      font-family: ${fonts.accent};
      display: inline-flex;
      align-items: center;
      padding: 1.25rem;
      line-height: 52px;
      height: 52px;
      width: 100%;
      text-align: center;
      justify-content: center;
    }

    &--menu {
      svg {
        fill: ${colours.primary};
      }
    }

    &--book {
      a {
        color: ${colours.primary};
      }

      svg {
        fill: ${colours.accent};

        path {
          stroke: #5a2813;
        }
      }
    }
  }

  .replace {
    display: none;
  }

  ${device.laptopM} {
    display: none;
  }
`;

const Hamburger = styled.div`
  width: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4px;
  margin-left: 4px;

  .line {
    position: absolute;
    width: 16px;
    height: 2px;
    background-color: ${colours.body};
    transform: translateY(4px);

    &:nth-of-type(1) {
      transform: translateY(-2px);
    }
  }
`;

export const query = graphql`
  query Nav {
    allSanityNavigation {
      edges {
        node {
          navId {
            current
          }
          title
          items {
            text
            navigationItemUrl {
              externalUrl
              internalLink {
                ... on SanityLocation {
                  slug {
                    current
                  }
                }
                ... on SanityMenu {
                  slug {
                    current
                  }
                }
                ... on SanityPage {
                  slug {
                    current
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
