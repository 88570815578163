import React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";
import { colours } from "../../vars/colours";
import Wrapper from "../global/Wrapper";
import { device } from "../../vars/media";
import { fonts } from "../../vars/fonts";
import Image from "../../images/footer-flare.svg";
import FadeUp from "../anims/FadeUp";
import Glyph from "../../images/glyph.svg";

const Footer = () => {
  const data = useStaticQuery(query);
  const menus = data.allSanityNavigation.edges;
  const menuLeft = menus.filter((m) => m.node.navId.current === "5");
  const menuRight = menus.filter((m) => m.node.navId.current === "6");

  return (
    <StyledFooter>
      <Wrapper>
        <FadeUp>
          <div className="footer__top">
            <div className="footer__menu">
              {menuLeft[0].node.items.map((item) => {
                return item.navigationItemUrl.externalUrl ? (
                  <a href={item.navigationItemUrl.externalUrl} target="_blank">
                    {item.text}
                  </a>
                ) : (
                  <Link
                    to={`/${item.navigationItemUrl.internalLink.slug.current}`}
                  >
                    {item.text}
                  </Link>
                );
              })}
            </div>
            <Image />
            <div className="footer__menu">
              {menuRight[0].node.items.map((item) => {
                return item.navigationItemUrl.externalUrl ? (
                  <a href={item.navigationItemUrl.externalUrl} target="_blank">
                    {item.text}
                  </a>
                ) : (
                  <Link
                    to={`/${item.navigationItemUrl.internalLink.slug.current}`}
                  >
                    {item.text}
                  </Link>
                );
              })}
            </div>
          </div>
          <div className="footer__bottom">
            <span>
              ©Copyright {new Date().getFullYear()} PASTURE RESTAURANT LIMITED.
              MADE BY{" "}
              <a href="mailto:hello@lukedagnall.com">
                <Glyph />
              </a>
            </span>
          </div>
        </FadeUp>
      </Wrapper>
    </StyledFooter>
  );
};

export default Footer;

const StyledFooter = styled.footer`
  font-family: ${fonts.accent};
  text-transform: uppercase;
  overflow: hidden;
  margin-top: 6.25rem;
  padding: 5rem 0 6rem 0;
  background-color: ${colours.body};
  color: ${colours.primary};
  text-align: center;

  .footer__menu {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    a {
      margin: 0.25rem 0;
      letter-spacing: 1.1px;
      transition: color 0.2s ease-in-out;

      &:hover {
        color: ${colours.accent};
      }
    }
  }

  .footer__top {
    display: flex;
    flex-direction: column;
    align-items: center;

    svg {
      width: 70%;
      margin-right: -5px;
      order: -1;
      margin-bottom: 1.25rem;
    }
  }

  .footer__bottom {
    font-size: 0.87rem;
    letter-spacing: 1.1px;
    margin-top: 3.25rem;

    svg {
      width: 18px;
      vertical-align: top;
    }

    a {
      cursor: pointer;
      &:hover {
        svg path {
          fill: ${colours.accent};
        }
      }
    }
  }

  span {
    letter-spacing: 1.2px;
  }

  ${device.laptop} {
    .footer__top {
      padding: 0 2.5rem;
      width: 100%;
      justify-content: space-between;
      svg {
        order: unset;
      }

      flex-direction: row;
    }
  }

  ${device.laptopM} {
    padding: 2rem 0;
    padding-top: 6rem;

    .footer__bottom {
      margin-top: 3.75rem;
    }
  }

  ${device.laptopL} {
    padding: 2rem 0;
    padding-top: 7.5rem;

    .footer__menu {
      a {
        font-size: 1.1rem;
      }
    }

    .footer__top {
      svg {
        width: 100%;
      }
    }

    .footer__bottom {
      margin-top: 6.75rem;
      font-size: 0.9rem;
    }
  }
`;

export const query = graphql`
  query Nav {
    allSanityNavigation {
      edges {
        node {
          navId {
            current
          }
          title
          items {
            text
            navigationItemUrl {
              externalUrl
              internalLink {
                ... on SanityLocation {
                  slug {
                    current
                  }
                }
                ... on SanityMenu {
                  slug {
                    current
                  }
                }
                ... on SanityPage {
                  slug {
                    current
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
