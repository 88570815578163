import React, { useContext, useRef } from "react";
import { TransitionGroup, Transition } from "react-transition-group";
import { gsap } from "gsap";
import ScrollSmoother from "gsap/ScrollSmoother";
import ScrollTrigger from "gsap/ScrollTrigger";
import styled from "styled-components";
import { cubic, quint, quintOut } from "../../vars/ease";
import { GlobalStateContext } from "../../context/Global";
import { useEffect } from "react";
import { colours } from "../../vars/colours";

const PageTransition = ({ children, location }) => {
  const { setTransitioned } = useContext(GlobalStateContext);
  const wipe = useRef(null);
  const newPage = useRef(null);

  return (
    <TransitionGroup>
      <Wipe ref={wipe} />
      <Transition
        key={location.pathname}
        appear={true}
        timeout={730}
        unmountOnExit
        onExit={(node) => {
          function isTouchDevice() {
            return (
              "ontouchstart" in window ||
              navigator.maxTouchPoints > 0 ||
              navigator.msMaxTouchPoints > 0
            );
          }

          const tlOut = gsap.timeline({
            paused: true,
            onComplete: () => {
              let smoother = ScrollSmoother.get();
              smoother.scrollTop(0);
            },
          });
          // const currentScroll = ScrollSmoother.get();
          // currentScroll.paused(true);

          gsap.set(document.querySelector("main"), {
            position: "absolute",
            width: "100%",
            // opacity: 0,
            // display: "none",
            // height: 0,
          });

          // gsap.set(document.querySelector("main:nth-of-type(2)"), {
          //   position: "unset",
          //   width: "100%",
          //   // display: "none",
          //   height: "100%",
          // });

          tlOut
            // .to(
            //   node.querySelectorAll(".inset, header"),
            //   {
            //     transformOrigin: "top",
            //     scale: 0.9,
            //     duration: 0.8,
            //     ease: quint,
            //   },
            //   0
            // )
            .to(
              node.querySelector(".ol"),
              {
                opacity: 1,
                duration: 0.8,
                ease: quint,
              },
              0
            )
            .to(
              wipe.current,
              {
                scaleY: 1,
                duration: 0.8,
                ease: quint,
              },
              0
            );

          tlOut.play(0);
        }}
        onEnter={(node) => {
          gsap.set(node, { opacity: 0 });
        }}
        onEntered={(node) => {
          setTransitioned(false);
          const tlIn = gsap.timeline({
            onStart: () => {
              ScrollTrigger.isTouch == 1 && window.scrollTo(0, 0);

              setTransitioned(true);
            },
            onComplete: () => {
              // currentScroll.current.paused(false);
              gsap.set(wipe.current, { clearProps: "all" });
            },
          });

          // const smoother = ScrollSmoother.create({
          //   effects: true,
          //   wrapper: ".inset",
          //   content: ".content",
          //   smooth: 1.1,
          //   smoothTouch: false,
          // });

          // smoother.scrollTop(0);
          // window.scrollTo(0, 0);
          // const currentScroll = ScrollSmoother.get();
          // currentScroll.scrollTop(0);

          gsap.set(node, { opacity: 1 });
          gsap.set(document.querySelector("main"), {
            clearProps: "all",
          });

          tlIn
            .to(
              wipe.current,
              {
                autoAlpha: 0,
                duration: 0.5,
                ease: cubic,
              },
              0.3
            )
            .fromTo(
              document.querySelector(".inset"),
              {
                y: 15,
              },
              {
                y: 0,
                duration: 1.17,
              },
              0.3
            );
        }}
      >
        {children}
      </Transition>
    </TransitionGroup>
  );
};

export default PageTransition;

const Wipe = styled.div`
  will-change: transform, opacity;
  background: ${colours.primary};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transform: scaleY(0);
  transform-origin: bottom;
  height: 100vh;
  z-index: 99;
`;
