import React from "react";
import styled from "styled-components";
import { device } from "../../vars/media";

const Wrapper = ({ children, outer }) => {
  return (
    <WrapperComponent className={!outer ? "wrapper wrapper__inner" : "wrapper"}>
      {children}
    </WrapperComponent>
  );
};

export default Wrapper;

const WrapperComponent = styled.div`
  padding: 0 0.625rem;
  margin: 0 auto;

  ${device.laptop} {
    /* padding: 0 3.125rem; */
  }

  &.wrapper__inner {
    margin: 0 auto;
    padding: 0 1.75rem;
    max-width: 1500px;

    ${device.laptop} {
      padding: 0 2.5rem;
    }

    ${device.laptopM} {
      padding: 0 3.5rem;
    }

    ${device.laptopL} {
      /* padding: 0; */
      /* padding: 0 2rem; */
    }
  }
`;
