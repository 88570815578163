import React from "react";
import styled from "styled-components";
import { colours } from "../../vars/colours";

const Caption = ({ children, className }) => {
  return (
    <StyledCaption className={`caption ${className && className}`}>
      {children}
    </StyledCaption>
  );
};

export default Caption;

const StyledCaption = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: ${colours.primary};
  color: ${colours.body};
  z-index: 2;
  padding: 2px 7px;
  margin: 1.25rem;
`;
